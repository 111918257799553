
import React from 'react'
import Container from "../container/Container";
export default function NavList({ children }) {
  return (
    <div className="nav-list-container">
      <Container>
        <ul className="nav-list">
          {children}
        </ul>
      </Container>
    </div>
  )
}
