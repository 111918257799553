import './Container.scss';
import React from 'react';

export default function Container({ children, size, noPadding }) {
  const applySize = size ? size : '';
  const applyPadding = noPadding ? 'no-padding' : '';
  
  return (
    <div className={`container ${applySize} ${applyPadding}`}>
      {children}
    </div>
  )
}
