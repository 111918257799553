import './StatCard.scss'
import React from 'react'
import Text from '../../text/Text';

export default function PreviewCard({ title, text, size }) {
  const classNames = []
  size && classNames.push(size)

  return (
    <div className="stat-card">
      <div className="stat-card-content">
        <Text type="h2" text={title} className={`underlined ${classNames.join(' ')}`}/>
        <Text type="p" text={text} className={`${classNames.join(' ')}`}/>
      </div>
    </div>
  )
}
