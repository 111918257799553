import { FETCH_ACCOUNT_TYPES } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_TYPES:
      return action.payload
    default:
      return state
  }
}
