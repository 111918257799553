import "./Form.scss";
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FormContainer from "../ui/container/form-container/FormContainer";
// import FormGroup from "./FormGroup";
import Button from "../ui/button/Button";
import Section from "../ui/section/Section";
import SectionHeader from "../ui/section/SectionHeader";
import { allLetter } from "../../utils/functions/index";
import { forgotPassword } from "../../actions/index";
import { renderInput, renderSelect, renderTextArea } from "./renderField";
import { Redirect } from 'react-router-dom';
import scrollToFirstError from './SmoothScrollToErrorFields.js';

class ForgotPassword extends Component {

  state = {
    redirectToNewPage: false
  }

  onSubmit = formValues => {
    if (this.props.forgotPassword(formValues)){
      this.setState({
        redirectToNewPage: true
      });
    }    
  };

  render() {
    if(this.state.redirectToNewPage){
      return <Redirect to="/reset-password-complete" />
    } else {
      return (
        <FormContainer>
          <Section id="service-form">
            <SectionHeader
              title="Reset Password"
              subtitle="Please complete the fields below (required *) and submit. Your request will be sent to our Client Care department who will be in contact with you. Should you have any problems, please contact us on 0861 90 90 90."
            />

            <form
              onSubmit={this.props.handleSubmit(this.onSubmit)}
              className="ui form error"
            >
              {'txtEmail' && <div name={`position-txtEmail`} />}
              <Field
                name="txtEmail"
                component={renderInput}
                label="Email"
                type="email"
              />
              {'txtContactNumber' && <div name={`position-txtContactNumber`} />}
              <Field
                name="txtContactNumber"
                component={renderInput}
                label="Contact Number"
                type="number"
              />
              {'txtContactName' && <div name={`position-txtContactName`} />}
              <Field
                name="txtContactName"
                component={renderInput}
                label="Contact Name"
                type="text"
              />
              {'txtMemberNumber' && <div name={`position-txtMemberNumber`} />}
              <Field
                name="txtMemberNumber"
                component={renderInput}
                label="Member Number"
                type="text"
              />

              <div className="form-footer centered">
                <Button type="submit" theme="green" text="SUBMIT" />
              </div>
            </form>
          </Section>
        </FormContainer>
      );
    }
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.txtContactName) {
    // on run if the user did not enter a title
    errors.txtContactName = "* You must enter your full name";
  }

  if (formValues.txtContactName) {
    let s = allLetter(formValues.txtContactName);
    if (!s) {
      errors.txtContactName = "* Please include only letters.";
    }
  }

  if (!formValues.txtMemberNumber) {
    // on run if the user did not enter a title
    errors.txtMemberNumber = "* You must enter a member number";
  }

  if (!formValues.txtContactNumber) {
    // on run if the user did not enter a title
    errors.txtContactNumber = "* You must enter a contact number";
  } else if (formValues.txtContactNumber.length !== 10) {
    errors.txtContactNumber = "* please enter a valid contact number";
  }

  if (!formValues.txtEmail) {
    // on run if the user did not enter a title
    errors.txtEmail = "* You must enter an email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(formValues.txtEmail)
  ) {
    errors.txtEmail = "* Invalid email address";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "ForgotPassword",
  validate: validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ForgotPassword);

export default connect(null, { forgotPassword })(formWrapped);
