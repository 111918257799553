import axios from "axios";
import { BASE_URL } from "../utils/paths";

export default axios.create({
  baseURL: BASE_URL,

  headers: {
    Authorization:
      "Basic QWNjb3VudGFiaWxpdHlfUHVibGljOnB+LVh5UytNcj0/SHNdbWYpQC5gS3BcRmBWPEtoREYyZA==",
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});
