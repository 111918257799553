import React, { Component } from "react";
import { connect } from "react-redux";
import { saveReferral } from "../../../actions/index";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { renderInput, renderIndexedSelect } from "../renderField";
import Button from "../../ui/button/Button";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import FormContainer from "../../ui/container/form-container/FormContainer";
import Text from "../../ui/text/Text";
import Recaptcha from "react-recaptcha";
import ReferralField from "./ReferralField";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class FormReferalFirstPage extends Component {

  constructor(props){
    super(props)
    this.verifyCallbackRecapcha = this.verifyCallbackRecapcha.bind(this);
    this.onloadCallbackRecapcha = this.onloadCallbackRecapcha.bind(this);
    this.state = {
      recapchaIsVerified: false
    }
  }  

  componentDidMount() {
    window.scrollTo(0, 0);
  };

  onloadCallbackRecapcha(){
    console.log('recapcha has loaded');
  }

  verifyCallbackRecapcha(response){
    if(response){
      this.setState({
        recapchaIsVerified: true
      }); 
    }      
    console.log('recapcha has been verified');
  }

  onSubmit = formValues => {
    console.log(formValues);
    if(this.state.recapchaIsVerified){
      this.props.saveReferral(formValues);
    } else {
      console.log('recapcha has not been verified');
      alert('Please verify that you are human!');
    }    
  };

  render() {
    const { handleSubmit } = this.props;

    const helpOptions = [
      {
        key: null,
        val: "Please select"
      },
      {
        key: 0,
        val: "Criminal Checks"
      },
      {
        key: 1,
        val: "Credit Checks"
      },
      {
        key: 2,
        val: "Registration"
      }
    ];

    const referalCount = [1, 2, 3, 4, 5];
    const renderReferalFields = () => {
      return referalCount.map(i => {
        return <ReferralField key={i} id={i} />;
      });
    };

    return (
      <React.Fragment>
        <Section>
          <SectionHeader
            noPadding
            autoWidth
            title="Refer 5 people who join Accountability & <br> receive a R1000 voucher."
          />

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <FormContainer>
              <Text type="h3" text="Personal Details" />
              {'personal_name' && <div name={`position-personal_name`} />}
              <Field
                name="personal_name"
                component={renderInput}
                label="Name & Surname:"
              />
              {'personal_contact_no' && <div name={`position-personal_contact_no`} />}
              <Field
                name="personal_contact_no"
                component={renderInput}
                label="Contact Number:"
              />
              {'personal_email' && <div name={`position-personal_email`} />}
              <Field
                name="personal_email"
                component={renderInput}
                label="Email Address:"
                type="email"
              />

              {'member_number' && <div name={`position-member_number`} />}
              <Field
                name="member_number"
                component={renderInput}
                label="Member Number:"
                type="text"
              />

              {renderReferalFields()}

              <div className="form-footer">
                <div className="form-footer-item">
                  <Recaptcha 
                    sitekey="6LfL17kUAAAAAOLB-BxODBlF9tIQCpESslxA_lLj" 
                    render="explicit"
                    onloadCallback={this.onloadCallbackRecapcha}
                    verifyCallback={this.verifyCallbackRecapcha}
                  />
                </div>

                <div className="form-footer-item">
                  <Button
                    type="submit"
                    theme="green"
                    text="SUBMIT"
                    classNames={["fat"]}
                  />
                </div>
              </div>
            </FormContainer>
          </form>
        </Section>
      </React.Fragment>
    );
  }
}

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: true, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(FormReferalFirstPage);

export default connect(null, { saveReferral })(formWrapped);
