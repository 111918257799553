// PAYFAST

// SANDBOX
// export const HOST = "https://sandbox.payfast.co.za";
// export const MERCHANT_ID = "10000100";
// export const MERCHANT_KEY = "46f0cd694581a";

// PRODUCTION
export const HOST = "https://www.payfast.co.za";
export const MERCHANT_ID = "11063569";
export const MERCHANT_KEY = "gb0hgjg3ygvaq";
