import "./Section.scss";
import React from "react";
import Text from "../text/Text";
import Container from "../container/Container";

export default function SectionHeader({
  title,
  subtitle,
  noPadding,
  autoWidth,
  paddingTop,
  leftAligned
}) {
  const classNames = [
    "section-header",
    noPadding && "no-padding",
    autoWidth && "width-auto",
    paddingTop && 'padding-top',
    leftAligned && 'left-aligned',
  ];
  const renderTitle = () => {
    return title && <Text type="h2" text={title} />;
  };

  const renderSubTitle = () => {
    return subtitle && <Text type="p" text={subtitle} />;
  };

  return (
    <Container>
      <header className={classNames.join(" ")}>
        {renderTitle()}
        {renderSubTitle()}
      </header>
    </Container>
  );
}
