import "./Popup.scss";
import React, { Component } from "react";

class Popup extends Component {

  state = {
    isConfirmed: true
  }

  componentDidMount() {
    setTimeout(() => { 
      this.setState({
        isConfirmed: false
      })
     }, 2000);
  }
  
  onConfirm = () => {
    this.setState({
      isConfirmed: !this.state.isConfirmed
    })
  }

  render() {
    return (
      <div>
        {!this.state.isConfirmed && (
          <div className="popup-overlay">
            <div className="popup-container">
              <div className="popup">
                <div onClick={this.onConfirm} className="popup-close">
                  <img src="/assets/image/close.svg" alt="X" />
                </div>
                <p>Dear Valued Member,<br />
                  Effective 1 July 2021, FNB will no longer issue bank codes to third parties.<br />
                  Read the FNB Correspondence <a href="/assets/pdf/FNB_Bank_Code_Communication.pdf" target="_blank" style={{textDecoration: 'underline', color: '#20A84A'}}><strong>here</strong></a></p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Popup;
