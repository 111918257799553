import "./Video.scss";
import React from "react";

const VideoDetail = props => {
  const renderVideo = () => {
    return (
      <div className="video-detail">
        <div className="ui embed">
          <iframe
            title="Accountability is a web-based service that was birthed with the aim of protecting businesses through reducing the risk caused by outstanding payments."
            src={props.url}
          />
        </div>
      </div>
    );
  };

  return renderVideo();
};

export default VideoDetail;
