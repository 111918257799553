import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { showModal, createRegisterPayment, verifyPaymentType } from "../../../actions/index";
import {
  // buildPayfastQuery,
  fetchLocalStorage
} from "../../../utils/functions/index";
import Button from "../../ui/button/Button";
import ButtonList from "../../ui/button/ButtonList";
import Container from "../../ui/container/Container";
import Modal from "../../ui/modal/Modal";
import ModalPortal from "../../ui/modal/ModalPortal";
import Text from "../../ui/text/Text";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";

class WizardFormFifthPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const userTokens = fetchLocalStorage("userTokens");
    this.props.createRegisterPayment(userTokens.registration_token);
  }
  onEFTSelect = () => {
    this.props.showModal(1);
    this.props.verifyPaymentType(this.props.registration.payment.registration_payment_token, 1)
  };

  onCASHSelect = () => {
    this.props.showModal(2);
    this.props.verifyPaymentType(this.props.registration.payment.registration_payment_token, 3)
  };

  onPayfastSelect = () => {
    this.props.nextPage();
    this.props.verifyPaymentType(this.props.registration.payment.registration_payment_token, 2)
  };

  render() {
    window.scrollTo(0, 0);

    return (
      <Section>
        <Container>
          <ModalPortal id="1">
            <Modal id="1">
              <CenteredBox stacked>
                <Text type="h2" text="EFT Payment" className="green" />
                <Text
                  type="p"
                  text="An Email has been sent with payment details."
                  className="green"
                />
                <Button
                  type="redirect"
                  url="register-complete"
                  text="NEXT"
                  theme="green"
                />
              </CenteredBox>
            </Modal>
          </ModalPortal>

          <ModalPortal id="2">
            <Modal id="2">
              <CenteredBox stacked>
                <Text type="h2" text="CASH Payment" className="green" />
                <Text
                  type="p"
                  text="Thank you for your payment of R349.00 (incl. VAT)"
                  className="green"
                />
                <Button
                  type="redirect"
                  url="register-complete"
                  text="NEXT"
                  theme="green"
                />
              </CenteredBox>
            </Modal>
          </ModalPortal>

          <ButtonList centered>
            <Button
              onClick={this.onPayfastSelect}
              text="PAY ONLINE VIA EFT / CREDIT CARD"
              theme="green"
            />

            <Button
              onClick={this.onEFTSelect}
              text="PAY VIA EFT"
              theme="green"
            />

            <Button
              onClick={this.onCASHSelect}
              text="PAY VIA CASH"
              theme="green"
            />
          </ButtonList>
        </Container>
      </Section>
    );
  }
}

const mapStateToProps = state => {
  return {
    registration: state.register
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(WizardFormFifthPage);

export default connect(mapStateToProps, { showModal, createRegisterPayment, verifyPaymentType })(formWrapped);
