import {
  FETCH_4_IN_1_TOKEN,
  FETCH_4_IN_1_QUESTIONS,
  CREATE_4_IN_1_PAYMENT,
  REMOVE_QUESTIONS,
  GENERATE_REPORT
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_4_IN_1_TOKEN:
      return { ...state, public_4in1_token: action.payload.public_4in1_token };
    case CREATE_4_IN_1_PAYMENT:
      return { ...state, payment: action.payload };
    case FETCH_4_IN_1_QUESTIONS:
      return { ...state, questions: action.payload };
    case REMOVE_QUESTIONS:
      return { ...state, questions: null };
    case GENERATE_REPORT:
      return { ...state, report: action.payload };
    default:
      return state;
  }
}
