import "./Footer.scss";
import React from "react";
import FormContact from "../../forms/FormContact";
import Footer from "./Footer";
import Section from "../section/Section";
import SectionHeader from "../section/SectionHeader";
import Container from "../container/Container";

function FooterWithForm() {
  return (
    <React.Fragment>
      <Container>
        <Section>
          <SectionHeader noPadding title="Get In Touch With Us" />
          <FormContact />
        </Section>
      </Container>
      <Footer />
    </React.Fragment>
  );
}

export default FooterWithForm;
