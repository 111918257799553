import React from "react";
import { Field } from "redux-form";
import { renderAssocSelect } from "../renderField";
import FormContainer from "../../ui/container/form-container/FormContainer";
import Text from "../../ui/text/Text";
// import scrollToFirstError from '../SmoothScrollToErrorFields.js';

const QuestionBlock = ({ options, description, index }) => {
  const fieldName = "public_4in1_answer_" + index;
  const questionName = "Question " + index;

  return (
    <React.Fragment>
      <Text type="h3" text={questionName} />
      <Text type="p" text={description} />
      <FormContainer noPadding leftAligned>
        {fieldName && <div name={`position-${fieldName}`} />}
        <Field
          name={fieldName}
          type="text"
          component={renderAssocSelect}
          options={options}
        />
      </FormContainer>
    </React.Fragment>
  );
};

export default QuestionBlock;
