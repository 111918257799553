import "./InfoCard.scss";
import React from "react";
import Icon from "../../icon/icon/Icon";
import Text from "../../text/Text";
export default function InfoCard({ image, title, text }) {
  return (
    <div className="info-card">
      <Icon>
        <img src={image} alt="" />
      </Icon>

      <Text type="h3" text={title} />
      <Text type="p" text={text} />
    </div>
  );
}
