import {
  REGISTER,
  CREATE_REGISTER_PAYMENT,
  FETCH_MEMBER_REFERENCE,
  FETCH_MEMBER_DETAILS,
  FETCH_AGENTS,
  VERIFY_PAYMENT_TYPE
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case REGISTER:
      return action.payload;
    case CREATE_REGISTER_PAYMENT:
      return { ...state, payment: action.payload };
    case FETCH_MEMBER_REFERENCE:
      return { ...state, company_reference: action.payload.company_reference };
    case FETCH_AGENTS:
      return { ...state, agents: action.payload.agents };
    case FETCH_MEMBER_DETAILS:
      return { ...state, memberDetails: action.payload };
    case VERIFY_PAYMENT_TYPE:
      return { ...state, paymentType: action.payload };
    default:
      return state;
  }
}
