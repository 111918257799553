import { allLetter } from "../../../utils/functions/index";

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const validate = values => {
  const errors = {};

  // COMPANY INFORMATION

  if (!values.public_4in1_firstname) {
    errors.public_4in1_firstname = "* Required";
  }

  if (values.public_4in1_firstname) {
    let s = allLetter(values.public_4in1_firstname);
    if (!s) {
      errors.public_4in1_firstname = "* Please include only letters.";
    }
  }

  if (!values.public_4in1_surname) {
    errors.public_4in1_surname = "* Required";
  }

  if (values.public_4in1_surname) {
    let s = allLetter(values.public_4in1_surname);
    if (!s) {
      errors.public_4in1_surname = "* Please include only letters.";
    }
  }

  if (!values.public_4in1_idnumber) {
    errors.public_4in1_idnumber = "* Required";
  }

  if (
    values.public_4in1_idnumber &&
    values.public_4in1_idnumber.length !== 13
  ) {
    errors.public_4in1_idnumber = "Please enter a valid ID number";
  }

  if(values.public_4in1_idnumber){
    let idNumber = values.public_4in1_idnumber;
    // get first 6 digits as a valid date
    let tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

    let id_date = ((tempDate.getDate()) < 10 ? '0' : '') + (tempDate.getDate());
    let id_month = ((tempDate.getMonth() + 1) < 10 ? '0' : '') + (tempDate.getMonth() + 1);
    let id_year = tempDate.getFullYear();
    let id_year_2020 = id_year + 100;

    let fullDate_1900 = id_year + "-" + (id_month) + "-" + id_date;
    let fullDate_2000 = id_year_2020 + "-" + (id_month) + "-" + id_date;

    let now = new Date();
    let nowFullYear = now.getFullYear();
    let thisyear = now.getFullYear().toString().substring(2) - 6;
    let id_year_2digits = id_year.toString().substring(2);
    if(id_year_2digits > thisyear){
      values.public_4in1_dateofbirth = fullDate_1900;
    } else {
      values.public_4in1_dateofbirth = fullDate_2000;
    }
  }

  if (!values.public_4in1_dateofbirth) {
    errors.public_4in1_dateofbirth = "* Required";
  }

  if (values.public_4in1_dateofbirth) {
    if(!values.public_4in1_idnumber){
      errors.public_4in1_idnumber = "Please enter a valid ID number";
    } 
  }

  if (!values.public_4in1_gender) {
    errors.public_4in1_gender = "* Required";
  }

  if (!values.public_4in1_cell_no) {
    errors.public_4in1_cell_no = "* Required";
  }

  if (values.public_4in1_cell_no) {
    if (values.public_4in1_cell_no.length !== 10) {
      errors.public_4in1_cell_no = "* Please enter a valid cellphone number";
    }
  }

  if (!values.public_4in1_work_no) {
    errors.public_4in1_work_no = "* Required";
  }

  if (values.public_4in1_work_no) {
    if (values.public_4in1_work_no.length !== 10) {
      errors.public_4in1_work_no = "* Please enter a valid cellphone number";
    }
  }

  if (!values.public_4in1_email) {
    errors.public_4in1_email = "* Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.public_4in1_email)
  ) {
    errors.public_4in1_email = "* Invalid email address";
  }

  if (!values.email_address_confirmation) {
    errors.email_address_confirmation = "* Required";
  }

  if (
    values.email_address_confirmation &&
    values.email_address_confirmation !== values.public_4in1_email
  ) {
    errors.email_address_confirmation = "Email and Confirm do not match.";
  }

  if (!values.agree_terms) {
    errors.agree_terms = "* Required";
  }

  if (!values.public_4in1_answer_1) {
    errors.public_4in1_answer_1 = "* Required";
  }
  if (!values.public_4in1_answer_2) {
    errors.public_4in1_answer_2 = "* Required";
  }
  if (!values.public_4in1_answer_3) {
    errors.public_4in1_answer_3 = "* Required";
  }
  if (!values.public_4in1_answer_4) {
    errors.public_4in1_answer_4 = "* Required";
  }
  if (!values.public_4in1_answer_5) {
    errors.public_4in1_answer_5 = "* Required";
  }

  return errors;
};

export default validate;
