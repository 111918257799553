import _ from "lodash";
import "./Home.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchServices, fetchTestimonials } from "../../../actions/index";
import { apiImagePath, chunk } from "../../../utils/functions/index";
import Loader from "../../ui/loader/Loader";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import PreviewCard from "../../ui/card/preview-card/PreviewCard";
import InfoCard from "../../ui/card/info-card/InfoCard";
import Container from "../../ui/container/Container";
import Gallery from "../../ui/custom-slider/Gallery";
import SliderItem from "../../ui/custom-slider/SliderItem";
import SliderContent from "../../ui/custom-slider/SliderContent";
import Text from "../../ui/text/Text";
import Button from "../../ui/button/Button";
import ButtonList from "../../ui/button/ButtonList";
import CenteredBox from "../../ui/structure/CenteredBox";
import BannerRegister from "../../ui/banner/BannerRegister";
import BannerAboutUs from "../../ui/banner/BannerAboutUs";
import BannerStats from "../../ui/banner/BannerStats";
import Testimonial from "../../ui/testimonial/Testimonial";
import { Helmet } from "react-helmet";

class Home extends Component {
  componentDidMount() {
    this.props.fetchServices();
    this.props.fetchTestimonials();
  }

  renderServiceChunks = () => {
    if (_.isEmpty(this.props.services)) {
      return <Loader />;
    }

    const serviceChunks = chunk(this.props.services.slice(0, 8), 4);
    return serviceChunks.map((chunk, index) => {
      return <Grid key={index}>{this.renderServices(chunk)}</Grid>;
    });
  };

  renderServices = chunk => {
    return chunk.map(service => {
      let serviceUrl = `/services/${service.slug}`;
      return (
        <GridItem key={service.id} classNames={["col-lg-3", "col-sm-6"]}>
          <PreviewCard
            image={apiImagePath(service.preview_image)}
            title={service.preview_title}
            text={service.preview_content}
            url={serviceUrl}
            truncated
          />
        </GridItem>
      );
    });
  };

  renderTestimonials = () => {
    if (_.isEmpty(this.props.testimonials)) {
      return <div>loading</div>;
    }

    return (
      <Gallery dotsMobile whiteIcons>
        {this.testimonialGalleryItems()}
      </Gallery>
    );
  };

  testimonialGalleryItems = () => {
    return this.props.testimonials.map(testimonial => {
      return (
        <SliderItem key={testimonial.id} autoHeight>
          <SliderContent centered full>
            <Testimonial text={testimonial.content} />
          </SliderContent>
        </SliderItem>
      );
    });
  };

  render() {
    const headerItems = [
      // {
      //   nobuttons: true,
      //   image: "/assets/image/festive-season-slider@2x.jpg",
      //   title: "FESTIVE SEASONS GREETINGS",
      //   subtitle: `The Accountability team would like to thank you, our valued Member, for your 
      //   continuous support during 2019. Our Office will be closed from the 21st December 2019 till the 7th January 2020. 
      //   <br><br> Wishing you a wonderful Festive Season and prosperity <br> in the New Year.`
      // },
      // {
      //   nobuttons: true,
      //   image: window.innerWidth > 800 ? "/assets/image/Website-Banner@2x.jpg" : "/assets/image/festive-season-banner-mobile@2x-mobile.jpg",
      //   headline: (<h2>Festive Seasons<br />Greetings</h2>),
      //   subtitle: `The Accountability Team would like to thank you, our valued member, for your continuous support during 2022. Kindly note that our office will be closed from the 22nd of December 2022 and will re-open on the 5th of January 2023. We wish you a wonderful Festive Season and prosperity in the New Year.`
      // },
      {
        url: "/services/consumer-criminal-check",
        image:
          "/assets/image/banners-optimized/protect-your-business-slider@2x.jpg",
        title: "Protect your business by ensuring sound hiring decisions.",
        subtitle: `Accountability’s Criminal Check verification service offers Members the ability to
                request background screening on an individual for pre- or post- employment reasons, 
                fraud investigations or migration requirements.`
      },
      {
        url: "/services/comprehensive-4-in-1-consumer-credit-report",
        image: "/assets/image/banners-optimized/comprehensive-slider@2x.jpg",
        title: "Comprehensive 4-in-1 Consumer Credit Report",
        subtitle: `Combining all your credit information retained by the 4 major South African based Credit Bureaus into an extensive, detailed report.`
      },
      {
        url: "/services/deed-searches",
        image: "/assets/image/banners-optimized/deed-searches-slider@2x.jpg",
        title: "Deed Searches",
        subtitle: `Our Members have access to information on properties in South Africa that belongs to a Consumer, Company or Trust.`
      }
    ];

    const renderHeaderGalleryItems = () => {
      return headerItems.map(
        ({ nobuttons, image, title, subtitle, url, headline }, i) => {
          return (
            <SliderItem key={i} image={image}>
              <SliderContent>
                {title && <Text type="h2" text={title} />}
                {headline && headline}
                <Text type="p" text={subtitle} />

                {!nobuttons && (
                  <ButtonList>
                    <Button
                      type="redirect"
                      text="LEARN MORE"
                      theme="green"
                      url={url}
                    />

                    <Button
                      type="redirect"
                      text="CONTACT US"
                      theme="purple-ghost"
                      url="/contact-us"
                    />
                  </ButtonList>
                )}
              </SliderContent>
            </SliderItem>
          );
        }
      );
    };

    const renderHomeContent = () => {
      return (
        <div className="home">
          <Gallery>{renderHeaderGalleryItems()}</Gallery>

          <Section id="section-services">
            <SectionHeader
              title="In-depth reports and specialised services at your fingertips"
              subtitle="From Consumer and Commercial reports to debt collection facilitation, 
            Accountability offers its Members a full spectrum of services which are available on our website, 24/7."
            />

            <Container>
              {this.renderServiceChunks()}
              <CenteredBox>
                <Button
                  type="redirect"
                  text="VIEW MORE"
                  theme="green"
                  url="/services"
                  classNames={["m-r-15", "m-t-10"]}
                />
              </CenteredBox>
            </Container>
          </Section>

          <BannerRegister />

          <BannerAboutUs />

          <div
            style={{
              background: `url('/assets/image/01-Home-Page/testimonials-purple-background.jpg')`,
              backgroundPosition: "center center",
              backgroundSize: "cover",

              width: "100%",
              position: "relative",
              backgroundAttachment: "fixed"
            }}
          >
            <Section>{this.renderTestimonials()}</Section>
          </div>

          <Section id="membership_benifits">
            <SectionHeader
              title="Membership Benefits"
              subtitle="Membership to Accountability comes with unique benefits over and above the services that we offer to our clients.
             We keep our Members connected and informed by encouraging networking, providing access to legal documents and advice, 
             equipping them with tools to make informed credit decisions and keeping them posted with the latest scams and debtor alerts."
            />

            <Container>
              <Grid>
                <GridItem classNames={["col-lg-3", "col-md-6", "col-sm-6"]}>
                  <InfoCard
                    image="/assets/image/icons/membership/downloads-and-advice.svg"
                    title="INTRA-MEMBER <br> NETWORKING"
                    text="Members agree to be contacted by other Members for marketing purposes and in return, they are offered the same opportunity."
                  />
                </GridItem>

                <GridItem classNames={["col-lg-3", "col-md-6", "col-sm-6"]}>
                  <InfoCard
                    image="/assets/image/icons/membership/intra-member-networking.svg"
                    title="DOWNLOADS <br> & ADVICE"
                    text="Members can access downloadable legal documents. When possible, we provide practical advice pertaining to their particular actions or legal standing."
                  />
                </GridItem>

                <GridItem classNames={["col-lg-3", "col-md-6", "col-sm-6"]}>
                  <InfoCard
                    image="/assets/image/icons/membership/accountability-stickers.svg"
                    title="ACCOUNTABILITY <br> STICKERS"
                    text="These digital or physical stickers can be attached to invoices or statements and indicate that the Member is equipped to recover unprescribed debts and mitigate risk."
                  />
                </GridItem>

                <GridItem classNames={["col-lg-3", "col-md-6", "col-sm-6"]}>
                  <InfoCard
                    image="/assets/image/icons/membership/accountability-security-alert.svg"
                    title="ACCOUNTABILITY <br> SECURITY ALERT"
                    text="In addition to highlighting habitual defaulters, Accountability offers its Members instant alerts of scams currently circulating."
                  />
                </GridItem>
              </Grid>
            </Container>
          </Section>

          <BannerStats />
        </div>
      );
    };

    const ldJson = {
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Accountability Group Pty Ltd",
      "image": "https://acc-admin.stg7.optimalonline.co.za/storage/services/October2019/n1aCgCsxzE3oUo8eceNS.jpg",
      "@id": "https://www.accountability.co.za/assets/image/accountability-logo.svg",
      "url": "https://www.accountability.co.za/",
      "telephone": "0861 90 90 90",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "29 Bella Rosa Street, Rosenpark, Bellville, Cape Town, 7530",
        "addressLocality": "Cape Town",
        "postalCode": "7530",
        "addressCountry": "ZA"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": -33.863972,
        "longitude": 18.641596
      },
      "openingHoursSpecification": [{
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday"
        ],
        "opens": "08:00",
        "closes": "16:30"
      },{
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Friday",
        "opens": "08:00",
        "closes": "15:00"
      }],
      "sameAs": [
        "https://www.facebook.com/accountabilityGroup",
        "https://twitter.com/AccyGroup",
        "https://www.instagram.com/accountabilitygroup/",
        "https://www.linkedin.com/company/accountability-group-pty-ltd"
      ] 
    }

    // VIEW STARTS HERE
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Debt Management Companies in Cape Town | Accountability Group Pty Ltd</title>
          {/* <meta
            name="description"
            content="Web based debt management company in Cape Town, aims at protecting businesses by reducing the risk caused by outstanding payments. Become a member now!"
          /> */}
          <meta
            name="description"
            content="From Consumer and Commercial reports to debt collection facilitation, Accountability offers its Members a full spectrum of services which are available on our website, 24/7."
          />
          
          <link rel="canonical" href={window.location} />
          <script type="application/ld+json">
              {JSON.stringify(ldJson)}
          </script>
        </Helmet>

        {renderHomeContent()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    services: Object.values(state.services),
    testimonials: Object.values(state.testimonials)
  };
};

export default connect(mapStateToProps, { fetchServices, fetchTestimonials })(
  Home
);
