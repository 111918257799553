import _ from 'lodash'
import { FETCH_SERVICE, FETCH_SERVICES } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_SERVICE:
      return { ...state, [action.slug]: action.payload };
    case FETCH_SERVICES:
      return {...state, ..._.mapKeys(action.payload, 'slug')};
    default:
      return state
  }
}
