import './ContactDetail.scss'
import React from 'react'

function ContactDetail({ children }) {
  return (
    <div className="contact-detail">
      {children}
    </div>
  )
}

export default ContactDetail
