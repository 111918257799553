import "./SocialFeed.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSocialFeed, updateSocialFeed } from "../../../actions";
import SocialCard from "../../ui/card/social-feed-card/SocialCard";
import Button from "../../ui/button/Button";
import Container from "../../ui/container/Container";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import BannerHeader from "../../ui/banner/BannerHeader";
import BannerRegister from "../../ui/banner/BannerRegister";
import Section from "../../ui/section/Section";
import CenteredBox from "../../ui/structure/CenteredBox";
import Loader from "../../ui/loader/Loader";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

class SocialFeed extends Component {
  componentDidMount() {
    this.props.fetchSocialFeed();
  }

  onLoadMoreArticles = () => {
    if (this.props.pagination.older === null) {
      this.props.fetchSocialFeed();
    }
    this.props.updateSocialFeed(this.props.pagination.older);
  };

  renderSocialCards = () => {
    const articles = this.props.articles;

    return articles.map(article => {
      return (
        <GridItem key={article.id} classNames={["col-lg-3", "col-sm-6"]}>
          <SocialCard article={article} />
        </GridItem>
      );
    });
  };

  renderContent() {
    if (!this.props.articles || !this.props.pagination) {
      return <Loader />;
    }

    return (
      <Section>
        <Grid>{this.renderSocialCards()}</Grid>
        <CenteredBox noPadding>
          <Button
            type="load-more"
            text={"LOAD MORE"}
            theme="green"
            onClick={this.onLoadMoreArticles}
          />
        </CenteredBox>
      </Section>
    );
  }

  renderPageContent() {
    return (
      <React.Fragment>
        <BannerHeader
          image="/assets/image/banners-optimized/social-feed-banner@2x-compressor.jpg"
          title="Social Feed"
        >
          <p className="white breadcrumb">
            <Link to="/">Home</Link> | <Link to="/social-feed">Social Feed</Link>
          </p>
        </BannerHeader>

        <Section>
          <Container>{this.renderContent()}</Container>
        </Section>
        <BannerRegister />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Social Feed | Accountability Group Pty Ltd</title>
          <meta
            name="description"
            content="Stay up to date with Accountability’s real-time Social Feed"
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        {this.renderPageContent()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: state.socialFeed.articles,
    pagination: state.socialFeed.pagination,
    isVerified: state.isVerified
  };
};

export default connect(
  mapStateToProps,
  {
    fetchSocialFeed,
    updateSocialFeed
  }
)(SocialFeed);
