import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchServices } from "../../../actions/index";
import BannerHeader from "../../ui/banner/BannerHeader";
import BannerRegister from "../../ui/banner/BannerRegister";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import PreviewCard from "../../ui/card/preview-card/PreviewCard";
import Container from "../../ui/container/Container";
import Loader from "../../ui/loader/Loader";
import { apiImagePath, chunk } from "../../../utils/functions";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

class Services extends Component {
  componentDidMount() {
    this.props.fetchServices();
  }

  renderServiceChunks = () => {
    if (_.isEmpty(this.props.services)) {
      return <Loader />;
    }

    const serviceChunks = chunk(this.props.services, 4);
    return serviceChunks.map((chunk, index) => {
      return <Grid key={index}>{this.renderServices(chunk)}</Grid>;
    });
  };

  renderServices = chunk => {
    return chunk.map(service => {
      let serviceUrl = `/services/${service.slug}`;

      return (
        <GridItem key={service.id} classNames={["col-lg-3", "col-sm-6"]}>
          <PreviewCard
            image={apiImagePath(service.preview_image)}
            title={service.preview_title}
            text={service.preview_content}
            url={serviceUrl}
            truncated
          />
        </GridItem>
      );
    });
  };

  renderPageContent() {
    return (
      <React.Fragment>
        <BannerHeader
          image="/assets/image/banners-optimized/services-banner@2x-compressor.jpg"
          title="Services"
        >
          <p className="white breadcrumb">
            <Link to="/">Home</Link> | <Link to="/services">Services</Link>
          </p>
        </BannerHeader>

        <Section id="section-services">
          <SectionHeader
            title="In-depth reports and specialised services at your fingertips"
            subtitle="From Consumer and Commercial reports to debt collection facilitation, Accountability offers its Members a full spectrum of services which are available on our website, 24/7."
          />

          <Container>{this.renderServiceChunks()}</Container>
        </Section>

        <BannerRegister />
      </React.Fragment>
    );
  }

  render() {
    // VIEW STARTS HERE
    return (
      <div className="services">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Services | Accountability Group Pty Ltd</title>
          <meta
            name="description"
            content="In-depth reports and specialised services at your fingertips. From Consumer and Commercial reports to debt collection facilitation, Accountability offers its Members a full spectrum of services which are available on our website, 24/7."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        {this.renderPageContent()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    services: Object.values(state.services)
  };
};

export default connect(mapStateToProps, { fetchServices })(Services);
