import './IconList.scss'
import React from 'react'

export default function IconList({ children, willStack, willStackMedium, centered, label }) {
  const classNames = [
    'icon-list',
    willStack && 'will-stack',
    willStackMedium && 'will-stack-medium',
    centered && 'centered'
  ]

  const renderChildren = () => {
   return (
    children.map((element, index) => {
      return <li key={index}>{element}</li>
    })
   )
  }

  return (
    <ul className={classNames.join(' ')}>
      {renderChildren()}
    </ul>
  )
}
