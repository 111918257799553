import _ from 'lodash'
import { FETCH_TESTIMONIALS } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_TESTIMONIALS:
      return {...state, ..._.mapKeys(action.payload, 'id')};
    default:
      return state
  }
}
