import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import BannerRegister from "../../ui/banner/BannerRegister";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import Text from "../../ui/text/Text";
import Video from "../../ui/video/VideoDetail";
import CenteredBox from "../../ui/structure/CenteredBox";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

const AboutUs = () => {
  return (
    <div className="about-us">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About | Accountability Group Pty Ltd</title>
        {/* <meta
          name="description"
          content="Accountability has emerged as a web based debt and credit Management agency, which provides its members access to an active credit database. Enquire Now!"
        /> */}
        <meta
          name="description"
          content="Accountability is a web-based service that was created by founder members Howard and Wendy Kemp, birthed by pure frustration, with the aim of “protecting” businesses by reducing the risk caused by outstanding payments."
        />
        
        <link rel="canonical" href={window.location} />
      </Helmet>

      <BannerHeader
        image="/assets/image/banners-optimized/about-us-banner@2x-compressor.jpg"
        title="About Us"
        // breadcrumb="Home | About Us"
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | <Link to="/about-us">About Us</Link>
        </p>
      </BannerHeader>
      <Container>
        <Section id="introduction">
          <SectionHeader noPadding title="Introduction to Accountability" />
          <Text
            type="p"
            className="centered"
            text="Accountability is a web-based service that was created by founder members Howard and Wendy Kemp, birthed by pure frustration, with the aim of “protecting” businesses by reducing the risk caused by outstanding payments."
          />
          <Text
            type="p"
            className="centered"
            text="As a registered Credit Bureau, our actions are determined by the provisions of the National Credit Act, No 34 of 2005 and the National Credit Regulator."
          />
        </Section>

        <Section id="value_proposition">
          <SectionHeader noPadding title="Our Value Proposition" />
          <Text
            type="p"
            className="centered"
            text="By partnering with the major Credit Bureaus, our Members have access to an active credit database of 25 
            million Consumers and 5.2 million Businesses."
          />
          <Text
            type="p"
            className="centered"
            text="Whether you are a start-up, SME or a large Corporate, by 
            becoming a valued Member of Accountability, you will have access to a variety of services whereby you can 
            accurately determine potential clients’ creditworthiness before extending them the required trade credit."
          />
          <Text
            type="p"
            className="centered"
            text="Accountability Members are able to access our user-friendly website 24 hours a day, 365 days of the year. "
          />
          <Text
            type="p"
            className="centered"
            text="
            A simple log in process will not only place our Members in direct contact with registered debt collectors,
             but also provide them with the tools to report Consumer and Commercial credit behaviour to registered Credit
              Bureaus, by instructing Accountability Solutions (Pty) Ltd to notify and list (after 28 calendar days from the initial notification) the Business, 
           Directors or in the case of a Consumer, the individual, as default payers. Simplifying the debtor management process with quick turnaround times and minimal effort."
          />
          <CenteredBox>
            <Video url="https://www.youtube.com/embed/_iCHmIPcq74" />
          </CenteredBox>
        </Section>

        <Section id="mission_statement">
          <SectionHeader noPadding title="Mission Statement" />
          <Text
            type="p"
            className="centered"
            text="Accountability is the dominant web-based service team aiming to protect SME’s and Corporates by providing them with tools to reduce credit risk and report Consumer and Commercial credit behaviour to registered Credit Bureaus."
          />
        </Section>

        <Section>
          <Grid>
            <GridItem
              classNames={["col-lg-6", "col-md-6", "col-sm-6", "m-b-25"]}
            >
              <Text type="h2" className="underlined" text="For Our Clients" />
              <ul className="has-dots mission-ul">
                <li>
                  Integrated results in terms of compliant Commercial and
                  Consumer Credit Reports.
                </li>
                <li>
                  Unrivalled, direct access to information from the major
                  national Credit Bureaus - anytime, anywhere.{" "}
                </li>
                <li>
                  Instantly accessible user-friendly website.
                </li>
                <li>
                  Fast and effective Credit Bureau listings.
                </li>
                <li>Increased cash-flow in 98% of cases.</li>
              </ul>
            </GridItem>

            <GridItem
              classNames={["col-lg-6", "col-md-6", "col-sm-6", "m-b-25"]}
            >
              <Text type="h2" className="underlined" text="For Our Employees" />
              <ul className="has-dots mission-ul">
                <li>An opportunity to share in our growth.</li>
                <li>A professional performance driven culture.</li>
                <li>
                  Job satisfaction based on equitable remuneration, personal
                  development and respect.
                </li>
              </ul>
            </GridItem>

            <GridItem
              classNames={["col-lg-6", "col-md-6", "col-sm-6", "m-b-25"]}
            >
              <Text type="h2" className="underlined" text="For Our Country" />
              <ul className="has-dots mission-ul auto-height">
                <li>Upholding high moral and ethical standards. </li>
                <li>Ensuring better accountability of the nation. </li>
                <li>
                  Social development programs addressing the plight of the
                  needy.
                </li>
              </ul>
            </GridItem>

            <GridItem
              classNames={["col-lg-6", "col-md-6", "col-sm-6", "m-b-25"]}
            >
              <Text
                type="h2"
                className="underlined"
                text="For Our Credit Bureau Partners"
              />
              <ul className="has-dots mission-ul auto-height">
                <li>
                  Constant updates of Commercial and Consumer credit data.
                </li>
              </ul>
            </GridItem>
          </Grid>
        </Section>
      </Container>

      <BannerRegister />
    </div>
  );
};

export default AboutUs;
