import "./Form.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import FormContainer from "../ui/container/form-container/FormContainer";
// import FormGroup from "./FormGroup";
import Button from "../ui/button/Button";
import { renderInput } from "./renderField";
import scrollToFirstError from './SmoothScrollToErrorFields.js';
import { getQueryString } from "../../utils/functions/index";
import Text from "../ui/text/Text";

class FormContact extends Component {

  constructor(props){
    super(props)
    this.state = {
      invalidLoginIsVerified: true
    }
    this.changeLoginVerifiedState = this.changeLoginVerifiedState.bind(this);
  }

  componentDidMount(){
    let loginFailed = getQueryString("e");
    if(loginFailed === 'Invalid+Login+Details'){
      this.setState({
        invalidLoginIsVerified: false
      });
    }
  }

  changeLoginVerifiedState(){
    this.setState({
      invalidLoginIsVerified: true
    });
  }

  onSubmit = formValues => {    
    this.props.createStream(formValues);
  };

  render() {
    return (

      <div>
       {!this.state.invalidLoginIsVerified && (
          <div className="age-gate-overlay">
            <div className="age-gate-container">
              <div className="age-gate">
                <Text type="h2" text="Invalid log in details" />
                <Button
                  onClick={this.changeLoginVerifiedState}
                  text="CLOSE"
                  theme="green"
                />
              </div>
            </div>
          </div>
        )}
        <FormContainer>
          <form
            action="https://www.accountability.co.za/index.php?c=index/x_mobile_group_login_web"
            method="POST"
            className="ui form error"
          >
          
            {'txtUsername' && <div name={`position-txtUsername`} />}
            <Field name="txtUsername" component={renderInput} label="Username:" />

            {'txtPassword' && <div name={`position-txtPassword`} />}
            <Field
              name="txtPassword"
              component={renderInput}
              label="Password:"
              type="password"
            />

            <Link to="reset-password">Forgot Password?</Link>
            <br />
            <br />
            <div className="form-footer centered">
              <Button type="submit" theme="green" text="SUBMIT" />
            </div>

          </form>
          
        </FormContainer>
      </div>

        
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.full_name) {
    // on run if the user did not enter a title
    errors.full_name = "* You must enter your full name";
  }

  if (!formValues.company_name) {
    // on run if the user did not enter a title
    errors.phone = "* You must enter a company name";
  }

  if (!formValues.phone) {
    // on run if the user did not enter a title
    errors.phone = "* You must enter a contact number";
  }

  if (!formValues.email) {
    // on run if the user did not enter a title
    errors.email = "* You must enter an email";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "formService",
  validate: validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(FormContact);

export default connect(null)(formWrapped);
