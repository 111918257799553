import "./FooterNav.scss";
import React from "react";
import { Link } from 'react-router-dom'

function FooterNav() {
  return (
    <nav className="footer-nav">
      <ul>
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/services">SERVICES</Link></li>
        <li><Link to="/about-us">ABOUT US</Link></li>
        <li><Link to="/news">NEWS</Link></li>
        <li><Link to="/social-feed">SOCIAL FEED</Link></li>
        <li><Link to="/contact-us">CONTACT US</Link></li>
        <li><Link to="/login">LOGIN</Link></li>
        <li><Link to="/register">REGISTER</Link></li>
      </ul>
    </nav>
  );
}

export default FooterNav;
