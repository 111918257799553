const validate = values => {
  const errors = {};

  // COMPANY INFORMATION

  if (!values.company_name) {
    errors.company_name = "* Required";
  }

  if (!values.company_registration_no) {
    errors.company_registration_no = "* Required";
  } else if (values.company_registration_no.length > 15) {
    errors.company_registration_no = "* Registration number too long. Maximum characters allowed is 15";
  }

  if (!values.company_vat_no) {
    errors.company_vat_no = "* Required";
  }

  if (!values.company_trading_as) {
    errors.company_trading_as = "* Required";
  }

  if (values.company_vat_no && values.company_vat_no.length !== 10) {
    errors.company_vat_no = "* please enter a valid VAT number";
  } else if (!/^[0-9]+$/i.test(values.company_vat_no)){
    errors.company_vat_no = "* Only digits allowed";
  }

  if (!values.company_tel_no) {
    errors.company_tel_no = "* Required";
  } else if (values.company_tel_no.length !== 10) {
    errors.company_tel_no = "* please enter a valid phone number";
  }

  if (!values.company_email) {
    errors.company_email = "* Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.company_email)
  ) {
    errors.company_email = "* Invalid email address";
  }

  if (!values.company_physical_address_line_1) {
    errors.company_physical_address_line_1 = "* Required";
  }

  if (!values.company_physical_address_line_2) {
    errors.company_physical_address_line_2 = "* Required";
  }

  if (!values.company_physical_address_line_3) {
    errors.company_physical_address_line_3 = "* Required";
  }

  if (!values.company_postal_address_code) {
    errors.company_postal_address_code = "* Required";
  }

  if (!values.company_agent) {
    errors.company_agent = "* Required";
  }

  if (!values.company_postal_same) {
    if (!values.company_postal_address_line_1) {
      errors.company_postal_address_line_1 = "* Required";
    }

    if (!values.company_postal_address_line_2) {
      errors.company_postal_address_line_2 = "* Required";
    }

    if (!values.company_postal_address_line_3) {
      errors.company_postal_address_line_3 = "* Required";
    }
  }

  // BANKING DETAILS
  if (!values.company_bank_account_name) {
    errors.company_bank_account_name = "* Required";
  }

  if (!values.company_bank_account_no) {
    errors.company_bank_account_no = "* Required";
  }

  if (!values.company_bank_name) {
    errors.company_bank_name = "* Required";
  }

  if (!values.company_bank_account_type) {
    errors.company_bank_account_type = "* Required";
  }

  if (!values.company_bank_branch_name) {
    errors.company_bank_branch_name = "* Required";
  }

  if (!values.company_bank_branch_code) {
    errors.company_bank_branch_code = "* Required";
  }

  // if (!values.company_signature_primary_data) {
  //   errors.company_signature_primary_data = "*  Signature required";
  // }

  

  // USER DETAILS

  //Authorised Signatory 01
  if (!values.company_signatory_name) {
    errors.company_signatory_name = "* Required";
  } else if (!/^[A-Za-z ]+$/i.test(values.company_signatory_name)){
    errors.company_signatory_name = "* Only letters allowed";
  } 

  if (!values.company_signatory_position) {
    errors.company_signatory_position = "* Required";
  } else if (!/^[A-Za-z ]+$/i.test(values.company_signatory_position)){
    errors.company_signatory_position = "* Only letters allowed";
  } 

  if (!values.company_signatory_email) {
    errors.company_signatory_email = "* Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
      values.company_signatory_email
    )
  ) {
    errors.company_signatory_email = "* Invalid email address";
  }

  //Authorised Signatory 02
  if (values.company_signatory_secondary_name && !/^[A-Za-z ]+$/i.test(values.company_signatory_secondary_name)){
    errors.company_signatory_secondary_name = "* Only letters allowed";
  } 

  if (values.company_signatory_secondary_position && !/^[A-Za-z ]+$/i.test(values.company_signatory_secondary_position)){
    errors.company_signatory_secondary_position = "* Only letters allowed";
  }

  if (values.company_signatory_secondary_email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
      values.company_signatory_secondary_email
    )
  ) {
    errors.company_signatory_secondary_email = "* Invalid email address";
  }

  //Primary User
  if (!values.company_primary_user_name) {
    errors.company_primary_user_name = "* Required";
  } else if (!/^[A-Za-z ]+$/i.test(values.company_primary_user_name)){
    errors.company_primary_user_name = "* Only letters allowed";
  } 

  if (!values.company_primary_position) {
    errors.company_primary_position = "* Required";
  } else if (!/^[A-Za-z ]+$/i.test(values.company_primary_position)){
    errors.company_primary_position = "* Only letters allowed";
  } 

  if (!values.company_primary_email) {
    errors.company_primary_email = "* Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
      values.company_primary_email
    )
  ) {
    errors.company_primary_email = "* Invalid email address";
  }

  //agree terms
  if (!values.agree_signtature_auth) {
    errors.agree_signtature_auth = "* Required";
  }

  if (!values.company_agree_terms) {
    errors.company_agree_terms = "* Required";
  }

  if (!values.company_agree_disclaimer) {
    errors.company_agree_disclaimer = "* Required";
  }

  if (!values.company_agree_privacy_policy) {
    errors.company_agree_privacy_policy = "* Required";
  }

  if (!values.agree_above) {
    errors.agree_above = "* Required";
  }

  //Extra Options stickers
  if(values.company_stickers_not_required){
    values.company_stickers_received = false;
    values.company_stickers_posted = false;
    values.company_stickers_electronic = false;
  }

  


  

  

  

   
  

  return errors;
};

export default validate;
