import React from 'react'

export default function CenteredBox({ children, noPadding, stacked }) {
  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0 0 0',
  }

  if(stacked) {
    styles.flexDirection = "column";
  }

  if(noPadding){
    styles.padding = 0;
  }

  return (
    <div className="centered-box" style={styles}>
      {children}
    </div>
  )
}
