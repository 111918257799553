import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { create4in1Payment } from "../../../actions/index";
import { buildPayfastQuery } from "../../../utils/functions/index";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import validate from "./validate";
import Text from "../../ui/text/Text";
import { v4 as uuid } from 'uuid';

class ReportRegisterThirdPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.fourInOneReport.public_4in1_token) {
      this.props.create4in1Payment(
        this.props.fourInOneReport.public_4in1_token
      );
    }
  }

  onSubmit = formValues => {
    const public_4in1_token = this.props.fourInOneReport.public_4in1_token;
    const public_4in1_payment_token = this.props.fourInOneReport.payment
      .public_4in1_payment_token;

    const tokens = {
      public_4in1_token: public_4in1_token,
      public_4in1_payment_token: public_4in1_payment_token,
      payment_id: uuid(),
      type: "4in1_report"
    };

    let lsuser = JSON.parse(localStorage.getItem("user4iN1Tokens"));

    const queryURL = buildPayfastQuery({
      userDetails: {
        first_name: lsuser.public_4in1_firstname,
        surname: lsuser.public_4in1_surname,
        email_address: lsuser.public_4in1_email
      },
      tokens,
      amount: 233.37,
      itemName: "Comprehensive 4-in-1 Report",
      returnURL: `/payment-complete?public_4in1_tokens=${JSON.stringify(
        tokens
      )}`,
      cancelURL: "/payment-cancel"
    });

    window.location.href = queryURL;
  };

  renderPayfastForm = () => {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <img src="../../../assets/image/payfast-logo.svg" alt="payfast-logo" />
        <div className="spacer"></div>
        <Text
          type="p"
          className="centered medium p-t-60"
          text="This is the PayFast process. When this form is completed and payment has been made you will be redirected to a thank you page."
        />
        <CenteredBox>
          <Button type="submit" text="NEXT" theme="green" />
        </CenteredBox>
      </form>
    );
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: 999,
          background: "#fff"
        }}
      >
        {this.renderPayfastForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fourInOneReport: state.fourInOneReport
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(ReportRegisterThirdPage);

export default connect(mapStateToProps, { create4in1Payment })(formWrapped);
