import React from "react";
import Banner from "./Banner";
import Text from "../text/Text";

function BannerHeader({ image, title, breadcrumb, scrollDown, noOverlay, children }) {
  return (
    <Banner
      image={image}
      height="300px"
      scrollDown={scrollDown}
      noOverlay={noOverlay}
    >
      <div>
        <Text type="h1" text={title} className="white" />
        {
          breadcrumb && <Text type="p" text={breadcrumb} className="white breadcrumb" />
        }
        {children}
      </div>
    </Banner>
  );
}

export default BannerHeader;
