import React from "react";
import { Field } from "redux-form";
import { renderInput, renderTextArea } from "../renderField";
import Spacer from "../../ui/structure/spacer/Spacer";
import Text from "../../ui/text/Text";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

function ReferralField({ id }) {
  const formatFieldName = (name, id) => {
    return name + id;
  };

  let companyName = formatFieldName("referral_company", id);
  let fullName = formatFieldName("referral_contact_person", id);
  let phone = formatFieldName("referral_contact_number", id);
  let email = formatFieldName("referral_contact_email", id);
  let message = formatFieldName("referral_contact_message", id);

  return (
    <React.Fragment>
      <Text type="h3" text={`Referral Details ${id}`} />

      {companyName && <div name={`position-${companyName}`} />}
      <Field name={companyName} component={renderInput} label="Company Name:" />

      {fullName && <div name={`position-${fullName}`} />}
      <Field name={fullName} component={renderInput} label="Contact Person:" />

      {phone && <div name={`position-${phone}`} />}
      <Field name={phone} component={renderInput} label="Contact Number:" />

      {email && <div name={`position-${email}`} />}
      <Field
        name={email}
        component={renderInput}
        label="Email Address:"
        type="email"
      />

      {message && <div name={`position-${message}`} />}
      <Field name={message} component={renderTextArea} label="Message:" />
      <Spacer />
    </React.Fragment>
  );
}

export default ReferralField;
