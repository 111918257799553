import React, { Component } from "react";
import { connect } from "react-redux";

class Notify extends Component {
  render() {
    return <div>1</div>;
  }
}

export default connect(null)(Notify);
