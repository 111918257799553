import './bootstrap.css'
import React from 'react'

export default function Grid({ children }) {
  return (
    <div className="row">
      {children}
    </div>
  )
}
