import React from "react";
import IconList from "../icon/icon-list/IconList";
import IconButton from "../icon/icon-button/IconButton";

function FooterSocialNav() {
  return (
    <IconList centered>
      <a
        href="https://www.facebook.com/accountabilityGroup"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton classNames={[]}>
          <img
            src="/assets/image/icons/white/large/facebook-logo-button.svg"
            alt="facebook logo"
          />
        </IconButton>
      </a>

      <a
        href="https://www.instagram.com/accountabilitygroup/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton classNames={[]}>
          <img
            src="/assets/image/icons/white/large/instagram.svg"
            alt="intagram logo"
          />
        </IconButton>
      </a>

      <a
        href="https://www.linkedin.com/company/accountability-group-pty-ltd"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton classNames={[]}>
          <img
            src="/assets/image/icons/white/large/linkedin.svg"
            alt="linked in logo"
          />
        </IconButton>
      </a>

      <a
        href="https://twitter.com/AccyGroup"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton classNames={[]}>
          <img
            src="/assets/image/icons/white/large/twitter-logo-button.svg"
            alt="twitter logo"
          />
        </IconButton>
      </a>
    </IconList>
  );
}

export default FooterSocialNav;
