import React, { Component } from "react";
import { connect } from "react-redux";
import { cancelPayment } from "../../actions/index";
import Container from "../ui/container/Container";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import HideOnMobile from "../ui/structure/hide-on-mobile/HideOnMobile";
import { getQueryString } from "../../utils/functions/index";

class PaymentCancel extends Component {
  componentDidMount() {
    this.props.cancelPayment("4in1_report", getQueryString("tokens"));
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <div
            className="form-success"
            style={{
              height: "600px",
              position: "relative",
              display: "flex",
              justifyContent: "left",
              alignItems: "center"
            }}
          >
            <div className="thank-you-content">
              <Text type="h2" text="Your order has been canceled" />

              <Button
                type="redirect"
                theme="green"
                text="BACK TO HOME"
                url="/"
              />
            </div>

            <HideOnMobile>
              <div
                className="thank-you-image"
                style={{ position: "absolute", bottom: 0, right: 0 }}
              >
                <img
                  src="/assets/image/people/Lady.png"
                  alt=""
                  style={{ maxWidth: "340px" }}
                />
              </div>
            </HideOnMobile>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  { cancelPayment }
)(PaymentCancel);
