import React from 'react'
import image from '../../../assets/image/main.png'

const Covid19 = () => {

    const styles = {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding: '20px',
        flexWrap:'wrap',
        textAlign:'center',
    }
    return(
        <div className="hr_covid-19" style={styles}>
            <div style={{margin:'10px'}}>
                <img src={image} alt="" width="250" />
            </div>
            <div>
                <p style={{marginBottom: '0'}}>For more information on COVID-19, visit <a target="_blank" href="https://sacoronavirus.co.za/">www.sacoronavirus.co.za</a></p>
            </div>
        </div>
    )
}

export default Covid19