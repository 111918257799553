import React from "react";
import IconList from "../../icon/icon-list/IconList";
import IconButton from "../../icon/icon-button/IconButton";

export default function SocialCardFooter(props) {
  return (
    <div className="social-footer">
      <div className="social-card-avatar">
        <div className="social-card-avatar-image">
          <a
            href="https://web.facebook.com/accountabilityGroup?_rdc=1&_rdr"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              src={props.article.attachments.facebook_post.profile_picture_url}
              alt=""
            />
          </a>
        </div>

        <div className="social-card-avatar-links">
          <a
            className="instagram-home"
            href="https://web.facebook.com/accountabilityGroup?_rdc=1&_rdr"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Accountability
          </a>{" "}
          <br />
          <a
            className="instagram-post"
            href={props.article.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Facebook
          </a>
        </div>
      </div>

      <div className="social-card-links">
        <IconList>
          <a
            href="https://web.facebook.com/accountabilityGroup?_rdc=1&_rdr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton classNames={[]}>
              <img
                src="/assets/image/icons/dark/facebook-logo-button.svg"
                alt=""
              />
            </IconButton>
          </a>

          <a
            href="https://twitter.com/AccyGroup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton classNames={[]}>
              <img
                src="/assets/image/icons/dark/twitter-logo-button.svg"
                alt=""
              />
            </IconButton>
          </a>
        </IconList>
      </div>
    </div>
  );
}
