import React from "react";

const FormGroup = ({ children, checkbox, noMargin }) => {
  const styles = [
    "form-group",
    checkbox && "checkbox",
    noMargin && "no-margin"
  ];

  return <div className={styles.join(" ")}>{children}</div>;
};

export default FormGroup;
