import React from "react";
import Container from "../container/Container";

export default function SliderContent({ children, full, centered }) {
  const classNames = [
    'slider-content',
    centered && 'centered',
    full && 'full'
  ]

  return (
    <div className="slider-content-container">
      <div>
        <Container>
          <div className={classNames.join(' ')}>{children}</div>
        </Container>
      </div>
    </div>
  );
}
