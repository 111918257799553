import "./PrivacyPolicy.scss";
import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import Section from "../../ui/section/Section";
import Container from "../../ui/container/Container";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchPage} from '../../../actions'
import Button from "../../ui/button/Button";
import ButtonList from "../../ui/button/ButtonList";

class PrivacyPolicy extends React.Component {

  componentDidMount(){
    this.props.fetchPage('privacy-policy')
  }

  renderBodyCopy = () => {
    return {__html:this.props.pageDetails.body }
  }

  render(){

    console.log(this.props.pageDetails)
    return (
      <div className="terms-and-conditions">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Privacy Policy | Accountability Group Pty Ltd</title>
          <meta
            name="description"
            content="It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing your personal data so that you are fully aware of how and why we are using your data."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        <BannerHeader
          image="/assets/image/banners-optimized/services-banner@2x-compressor.jpg"
          title="Website Privacy Policy"
        >
          <p className="white breadcrumb">
            <Link to="/">Home</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </BannerHeader>
        <Section>
          <Container>
            <Grid>
              <GridItem classNames={["col-md-12 privacy-policy"]}>

                {
                  this.props.pageDetails && (
                    <div dangerouslySetInnerHTML={this.renderBodyCopy()}></div>
                  )
                }

                <ButtonList>

                    <div class="button purple-ghost " style={{margin: '30px auto'}}><a href="/assets/pdf/Privacy-Policy-June-2021-Process-05.pdf" target="_blank"><div class="buttonSlide"></div><button>Download PDF</button></a></div>
                </ButtonList>

                {/* Privacy-Policy-June 2021-Process-03.pdf */}

                {/* <h2 className="fontPurple">
                  Introduction
                </h2>

                <p>Welcome to the Accountability Group (Pty) Ltd and Accountability Solutions (Pty) Ltd (“Accountability”, “we” or “us”) privacy notice. Accountability Group (Pty) Ltd and Accountability Solutions (Pty) Ltd (collectively referred herein as ‘Accountability’) are companies registered in the Republic of South Africa under company registration numbers 2008/012163/07 and 2017/365254/07 respectively.</p>

                <p>By clicking on the "Submit" option or by using this Website, you agree to be bound by these Terms and Conditions of Use, as amended from time to time. If you do not agree, you may not make use of the Accountability Services.</p>

                <p>Accountability respects your privacy and is committed to protecting your personal data. This privacy notice will inform you as to how we protect your personal data when you visit our website. www.accountability.co.za (“Website”), regardless of where you visit it from and inform you about your privacy rights and how the law protects you. </p>

                <h2 className="fontPurple">Who we are:</h2> 

                <h2 className="fontPurple">Purpose of this privacy notice</h2>

                <p>This privacy notice aims to provide you with information on how Accountability collects and processes your personal data through your use of the Website. This includes any data you may provide through the Website when you submit an enquiry and/or register.</p>

                <p>This Website is not intended for children and we do not knowingly collect data relating to children.</p>

                <p>It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing your personal data so that you are fully aware of how and why we are using your data. This privacy notice supplements the other notices and is not intended to override them. </p>

                <h2 className="fontPurple">Controller</h2>

                <p>Accountability is the controller and is responsible for your personal data.</p>

                <p>Accountability Group (Pty) Ltd is a South African reseller Credit Bureau, registered with the National Credit Regulator in terms of Section 43 of the National Credit Act, No 34 of 2005, under registration number NCRCB33.</p> 
                
                <p>Accountability Solutions (Pty) Ltd is a registered debt collector with the Council for Debt Collectors under registration number 0117675/19.</p> 
                  
                <p>This privacy notice is issued on behalf of Accountability Group (Pty) Ltd and Accountability Solutions (Pty) Ltd, collectively referred to as Accountability, "we", "us" or "our" in this privacy notice. When any of the aforementioned terms are used, we are referring to Accountability who responsible for processing your data and who is the controller of, and responsible for, the Website.</p>

                <p>We have appointed a data privacy manager who is responsible for overseeing questions in relation to this privacy notice. This position is fulfilled by the companies’ Compliance Officer. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the data privacy manager using the details set out below.</p>

                <h2 className="fontPurple">Contact details</h2>

                <p>Our full details are:</p>

                <p>Full name of legal entity: Accountability Group (Pty) Ltd and Accountability Solutions (Pty) Ltd (collectively referred herein as ‘Accountability’).</p>

                <p>Name of Data privacy manager: L J du Plessis</p>

                <p>Email address: disputes@accountability.co.za</p>

                <p>Postal address: PO Box 1078, Brackenfell, 7560</p>

                <p>You have the right to file a complaint at any time at the South African Information Regulator (www.justice.gov.za). We would, however, appreciate the chance to deal with your concerns before you approach the Regulator so please contact us on disputes@accountability.co.za in the first instance.</p>

                <h2 className="fontPurple">Changes to the privacy notice and your duty to inform us of changes</h2>

                <p>This version is effective from 12 March 2020</p>

                <p>It is important that the personal data held by Accountability is accurate and current. Please keep us informed if your personal data changes during your relationship with us. You are also able to update your details at any time on our website.</p>

                <h2 className="fontPurple">Third-party links</h2>

                <p>The Website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our Website, we encourage you to read the privacy notice of every website you visit.</p>

                <h2 className="fontPurple">The data we collect about you</h2>

                <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>

                <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together follows:</p>

                <ul className="terms-list">
                  <li>
                    <span>1.</span>
                    <p><strong>Identity Data</strong> includes first names, last name, title, date of birth, gender (if applicable), age and passport or identity numbers.</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p><strong>Contact Data</strong> includes billing address, email address, postal address and telephone numbers.</p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p><strong>Financial Data</strong> includes payment card details.</p>
                  </li>
                  <li>
                    <span>4.</span>
                    <p><strong>Transaction Data</strong> includes details about payments you make to us and other details of bookings you have made.</p>
                  </li>
                  <li>
                    <span>5.</span>
                    <p><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access the Website.</p>
                  </li>
                  <li>
                    <span>6.</span>
                    <p><strong>Profile Data</strong> includes your username and password, Accountability purchases made by you, your interests, preferences and feedback.</p>
                  </li>
                  <li>
                    <span>7.</span>
                    <p><strong>Usage Data</strong> includes information about how you use the Website and services including but not limited to cookies.</p>
                  </li>
                  <li>
                    <span>8.</span>
                    <p><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>
                  </li>
                </ul>

                <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity.</p>

                <p>We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data).</p>

                <h2 className="fontPurple">If you fail to provide personal data</h2> 

                <p>Where we need to collect personal data under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you. In this case, we may have to cancel a registration/contract/purchase you have with us but we will notify you if this is the case at the time. </p>

                <h2 className="fontPurple">How is your personal data collected?</h2> 
                            
                <p>We use different methods to collect data from and about you including through:</p>

                <ul className="terms-list">
                  <li>
                    <span>1.</span>
                    <p><strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you make a purchase, request marketing to be sent to you or give us feedback.</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p><strong>Automated technologies or interactions.</strong> As you interact with the Website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.</p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p><strong>Third parties or publicly available sources.</strong> We may receive personal data about you from various third parties as set out below:</p>
                  </li>
                </ul>

                <p><strong>Technical Data from the following parties:</strong></p>

                <p>(a) Analytics providers such as Google.</p>

                <p>(b) Advertising networks such as Google Ads, Facebook, Twitter, Instagram or Linked In.</p>

                <p>(c) Search information providers.</p>

                <p>(d) Contact, Financial and Transaction Data from providers of technical and payment services.</p>

                <h2 className="fontPurple">How we use your personal data</h2> 

                <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>

                <ul className="terms-list">
                  <li>
                    <span>1.</span>
                    <p>Where we need to perform the contract, we are about to enter into or have entered into with you.</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</p>
                  </li>
                  <li>
                    <span>3.</span>
                    <p>Where we need to comply with a legal or regulatory obligation.</p>
                  </li>
                </ul>

                <p>Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to sending third party direct marketing communications to you via email or message. You have the right to withdraw consent to marketing at any time by contacting us directly or unsubscribing to our communication channels.</p>

                <h2 className="fontPurple">Purposes for which we will use your personal data</h2> 

                <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>

                <p>Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us on disputes@accountability.co.za if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>

                <table className="privacy-policy_table" valign="top">
                  <tr>
                    <th>Purpose / Activity</th>
                    <th>Type of data</th>
                    <th>Lawful basis for processing including basis of legitimate interest</th>
                  </tr>
                  <tr>
                    <td><p>To register you as a new customer</p></td>
                    <td>
                      <p>(a) Identity</p>
                      <p>(b) Contact</p>
                    </td>
                    <td><p>Performance of a contract with you</p></td>
                  </tr>
                  <tr>
                    <td>
                      <p>To process your registration including:</p>
                      <p>(a) Manage payments, fees and charges</p>
                      <p>(b) Collect and recover money owed to us</p>
                    </td>
                    <td>
                      <p>(a) Identity</p>
                      <p>(b) Contact</p>
                      <p>(c) Financial</p>
                      <p>(d) Transaction</p>
                      <p>(e) Marketing and Communications</p>
                    </td>
                    <td>
                      <p>(a) Performance of a contract with you</p>
                      <p>(b) Necessary for our legitimate interests (to recover debts due to us)</p>
                    </td>
                  </tr>
                  <tr>
                  <td>
                      <p>To manage our relationship with you which will include:</p>
                      <p>(a) Notifying you about changes to our terms or privacy policy</p>
                      <p>(b) Asking you to leave a review or take a survey</p>
                    </td>
                    <td>
                      <p>(a) Identity</p>
                      <p>(b) Contact</p>
                      <p>(c) Profile</p>
                      <p>(d) Marketing and Communications</p>
                    </td>
                    <td>
                      <p>(a) Performance of a contract with you</p>
                      <p>(b) Necessary to comply with a legal obligation</p>
                      <p>(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our services)</p>
                    </td>
                  </tr>
                  <tr>
                  <td>
                      <p>To administer and protect our business and the Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</p>
                    </td>
                    <td>
                      <p>(a) Identity</p>
                      <p>(b) Contact</p>
                      <p>(c) Technical</p>
                    </td>
                    <td>
                      <p>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the con of a business reorganisation or group restructuring exercise)</p>
                      <p>(b) Necessary to comply with a legal obligation</p>
                    </td>
                  </tr>
                  <tr>
                  <td>
                      <p>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</p>
                    </td>
                    <td>
                      <p>(a) Identity</p>
                      <p>(b) Contact</p>
                      <p>(c) Profile </p>
                      <p>(d) Usage</p>
                      <p>(e) Marketing and Communications</p>
                      <p>(f) Technical</p>
                    </td>
                    <td>
                      <p>Necessary for our legitimate interests (to study how customers use our services, to develop them, to grow our business and to inform our marketing strategy)</p>
                    </td>
                  </tr>  
                  <tr>
                    <td>
                      <p>To use data analytics to improve our Website, services, marketing, customer relationships and experiences</p>
                    </td>
                    <td>
                      <p>(a) Technical</p>
                      <p>(b) Usage</p>
                    </td>
                    <td>
                      <p>Necessary for our legitimate interests (to define types of customers for our services, to keep our Website updated and relevant, to develop our business and to inform our marketing strategy)</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>To make suggestions and recommendations to you about goods or services that may be of interest to you</p>
                    </td>
                    <td>
                      <p>(a) Identity</p>
                      <p>(b) Contact </p>
                      <p>(c) Technical </p>
                      <p>(d) Usage </p>
                      <p>(e) Profile </p>
                    </td>
                    <td>
                      <p>Necessary for our legitimate interests (to develop our services and grow our business)</p>
                    </td>
                  </tr>
                </table> 

                <h2 className="fontPurple">Lawful basis</h2> 
                <p>In the above table, the lawful basis referred to means:</p>
                <p><strong>Legitimate Interest</strong> - the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us on disputes@accountability.co.za</p>
                <p><strong>Performance of Contract</strong> - processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
                <p>Comply with a legal or regulatory obligation - processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</p>

                <h2 className="fontPurple">Marketing </h2>

                <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising</p>

                <p><strong>Promotional offers from us</strong></p>

                <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which services and offers may be relevant for you (we call this marketing).</p>

                <p>You will receive marketing communications from us if you have requested information from us, purchased services from us registered with us, or made an enquiry via the Website and, in each case, you have not opted out of receiving that marketing.</p>

                <h2 className="fontPurple">Third-party marketing</h2> 

                <p>We will get your express opt-in consent before we share your personal data with any company outside the Accountability group of companies for marketing purposes.</p>

                <h2 className="fontPurple">Opting out</h2> 

                <p>You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time or unsubscribing from the relevant marketing channel/communication piece.</p>

                <p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a registration or other transaction.</p>

                <h2 className="fontPurple">Cookies</h2> 

                <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our cookie policy.</p>

                <h2 className="fontPurple">Change of purpose</h2> 

                <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us on disputes@accountability.co.za</p>

                <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>

                <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>

                <h2 className="fontPurple">Disclosures of your personal data</h2>

                <p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.</p>

                <p><strong>Internal Third Parties</strong></p>

                <p>Accountability office divisions that provide IT and system administration services and undertake leadership reporting.</p>

                <p><strong>External Third Parties</strong></p>

                <ul className="terms-list">
                  <li>
                    <span>1.</span>
                    <p>Service providers acting as processors based in South Africa who provide IT and system administration services.</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in South Africa who provide consultancy, banking, legal, insurance and accounting services.</p>
                  </li>
                </ul>

                <p>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</p>

                <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>

                <h2 className="fontPurple">Data security</h2>

                <p>We have put in place appropriate security measures compliant with ISO 9001, ISO 27001 and Credit Bureau Association (CBA) guidelines, to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>

                <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>

                <h2 className="fontPurple">Data retention</h2>

                <p><strong>How long will you use my personal data for?</strong></p>

                <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

                <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>

                <p>In some circumstances you can ask us to delete your data.</p>

                <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>

                <h2 className="fontPurple">Your legal rights</h2>

                <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. You have the right to:</p>
                <p>Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
                <p><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
                <p><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
                <p><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
                <p><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it. </p>
                <p><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
                <p><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
                <p>If you wish to exercise any of the rights set out above, please contact us on disputes@accountability.co.za. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
                <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this</p>

                <h2 className="fontPurple">Accountability</h2>

                <p>Accountability comprises the following companies:</p>

                <ul className="terms-list">
                  <li>
                    <span>1.</span>
                    <p>Accountability Group (Pty) Ltd</p>
                  </li>
                  <li>
                    <span>2.</span>
                    <p>Accountability Solutions (Pty) Ltd</p>
                  </li>
                </ul> */}

              </GridItem>
            </Grid>

            
          </Container>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageDetails: state.pages['privacy-policy']
  }
}

export default connect(mapStateToProps, {
  fetchPage
})(PrivacyPolicy);
