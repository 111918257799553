import "./Navbar.scss";
import React from "react";

export default function Navbar({ children, isActive }) {
  return (
    <nav className={`navbar ${isActive}`}>
      {children}
    </nav>
  );
}
