import { SHOW_FEEDBACK, HIDE_FEEDBACK } from '../types'

export const showFeedback = (show, message, feedbackType) => {
  return {
    type: SHOW_FEEDBACK,
    show,
    feedbackType,
    message: {
      text: message
    }
  }
}

export const hideFeedback = () => {
  return{
    type: HIDE_FEEDBACK,
    payload: {
      show: false
    }
  }
}


