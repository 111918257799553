import "../assets/scss/App.scss";
import React, { Component, Fragment } from "react";
import AppDisplay from "./ui/container/AppDisplay";
import TopFeedback from "./ui/feedback/TopFeedback";
import TopFeedbackPortal from "./ui/feedback/TopFeedbackPortal";
import Header from "./ui/header/Header";
import ScrollToTop from "./behaviour/ScrollToTop";
import Routes from "../routes/Routes";
import FooterRoutes from "../routes/FooterRoutes";
// import Message from "../components/ui/popup/Message";
// import history from "../utils/history/history";

class App extends Component {
  render() {
    return (
      <Fragment>
        <TopFeedbackPortal>
          <TopFeedback />
        </TopFeedbackPortal>
        <Header />

        {/* {history.location.pathname !== "/payment-complete" && <AgeGate />} */}
        {/* <Message /> */}

        <AppDisplay>
          <ScrollToTop>
            <Routes />
            <FooterRoutes />
          </ScrollToTop>
        </AppDisplay>
      </Fragment>
    );
  }
}

export default App;
