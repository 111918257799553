import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import Section from "../../ui/section/Section";
import Container from "../../ui/container/Container";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import ButtonList from "../../ui/button/ButtonList";
import CenteredBox from "../../ui/structure/CenteredBox";
import Button from "../../ui/button/Button";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

function PageNotFound404() {
  return (
    <div className="terms-and-conditions">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Page Not Found | Accountability</title>
        <meta
          name="description"
          content="The page you were looking for can not be found."
        />
        <meta name="robots" content="noindex" />
      </Helmet>

      <BannerHeader
        image="/assets/image/banners-optimized/services-banner@2x-compressor.jpg"
        title="404 Page Not Found"
        // breadcrumb="Home | Cookie Policy"
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | 404 Page Not Found
        </p>
      </BannerHeader>
      <Section>
        <Container>
          <CenteredBox>
            <ButtonList>
                <Button
                    type="redirect"
                    text="EXPLORE"
                    theme="green"
                    url="/"
                />

                <Button
                    type="redirect"
                    text="CONTACT US"
                    theme="purple-ghost"
                    url="/contact-us"
                />
            </ButtonList>
          </CenteredBox>
        </Container>
      </Section>
    </div>
  );
}

export default PageNotFound404;
