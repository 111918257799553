import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { fetchAgents } from "../../../actions/index";
import validate from "./validate";
import {
  renderInput,
  renderCheckbox,
  renderIndexedSelect,
  FieldFileInput,
} from "../renderField";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import FormContainer from "../../ui/container/form-container/FormContainer";
import { connect } from "react-redux";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class WizardFormFirstPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postalSame: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 10);
    this.props.fetchAgents();
  }

  handlePostalSame = () => {
    this.setState({ postalSame: !this.state.postalSame });
  };

  createAgentOptions = () => {
    let agentOptions = [];

    if (this.props.agents) {
      for (let property in this.props.agents) {
        if (
          this.props.agents.hasOwnProperty(property) &&
          property.charAt(0) !== "_"
        ) {
          let item = {
            key: property,
            val: this.props.agents[property]
          };

          agentOptions.push(item);
        }
      }
    }

    agentOptions
      .sort((a, b) => (a.val > b.val ? 1 : -1))
      .unshift({
        key: null,
        val: "Please select"
      });

    return agentOptions;
  };

  //displays the upload below the input field and resets the drawing pad
  handleImageUpload = async data => {
    await this.setState({ upload: data });
    this.props.change("proof_of_address", data);
    // this.upload.current.src = URL.createObjectURL(data);
  };

  //base64 encode the file for uploading
  async handleImageChange(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.setState({ upload: reader.result });
        resolve(resolve);
      };
    });
  }

  onSubmit = async formValues => {
    let upload = formValues.proof_of_address

    if(upload){
      if (upload.type !== undefined && upload.type === "application/pdf") {
        await this.handleImageChange(upload);
        console.log(
          "this.state.upload",
          this.state.upload
        );
        formValues.proof_of_address = this.state.upload;
      }
    }

    this.props.onSubmit()
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <React.Fragment>
        <Section id="section-register">
          <SectionHeader
            autoWidth
            title="Begin Your Registration"
            subtitle="Should you require an appointment with an Accountability representative please contact us on 0861 90 90 90, alternatively please complete the form below."
          />
        </Section>

        <Section>
          <FormContainer>
            <SectionHeader autoWidth noPadding title="Company Information" />
            <form onSubmit={handleSubmit(this.onSubmit)}>
              {'company_name' && <div name={`position-company_name`} />}
              <Field
                name="company_name"
                type="text"
                component={renderInput}
                label="Registered Company Name / Sole Proprietor Name"
              />
              {'company_trading_as' && <div name={`position-company_trading_as`} />}
              <Field
                name="company_trading_as"
                type="text"
                component={renderInput}
                label="Trading as"
              />
              {'company_registration_no' && <div name={`position-company_registration_no`} />}
              <Field
                name="company_registration_no"
                type="number"
                component={renderInput}
                label="Company Registration Number / Sole Proprietor ID"
              />
              {'company_vat_no' && <div name={`position-company_vat_no`} />}
              <Field
                name="company_vat_no"
                type="text"
                component={renderInput}
                label="VAT Number"
              />
              {'company_tel_no' && <div name={`position-company_tel_no`} />}
              <Field
                name="company_tel_no"
                type="number"
                component={renderInput}
                label="Telephone / Cell phone"
              />
              {'company_email' && <div name={`position-company_email`} />}
              <Field
                name="company_email"
                type="email"
                component={renderInput}
                label="Email"
              />

              <Grid>
                <GridItem classNames={["col-md-6"]}>
                  <label htmlFor="">Physical Address</label>
                  {'company_physical_address_line_1' && <div name={`position-company_physical_address_line_1`} />}
                  <Field
                    name="company_physical_address_line_1"
                    type="text"
                    component={renderInput}
                    label="Address Line 1"
                  />
                  {'company_physical_address_line_2' && <div name={`position-company_physical_address_line_2`} />}
                  <Field
                    name="company_physical_address_line_2"
                    type="text"
                    component={renderInput}
                    label="Address Line 2"
                  />
                  {'company_physical_address_line_3' && <div name={`position-company_physical_address_line_3`} />}
                  <Field
                    name="company_physical_address_line_3"
                    type="text"
                    component={renderInput}
                    label="Address Line 3"
                  />
                  {'company_postal_address_code' && <div name={`position-company_postal_address_code`} />}
                  <Field
                    name="company_postal_address_code"
                    type="text"
                    component={renderInput}
                    label="Postal / Zip Code"
                  />
                </GridItem>

                <GridItem classNames={["col-md-6"]}>
                  <label htmlFor="">Postal Address</label>
                  {'company_postal_same' && <div name={`position-company_postal_same`} />}
                  <Field
                    name="company_postal_same"
                    type="checkbox"
                    component={renderCheckbox}
                    label="Same as physical"
                    noMargin={false}
                    onChange={this.handlePostalSame}
                  />

                  {!this.state.postalSame && (
                    <div className="company-postal-same">
                      {'company_postal_address_line_1' && <div name={`position-company_postal_address_line_1`} />}
                      <Field
                        name="company_postal_address_line_1"
                        type="text"
                        component={renderInput}
                        label="Address Line 1"
                      />
                      {'company_postal_address_line_2' && <div name={`position-company_postal_address_line_2`} />}
                      <Field
                        name="company_postal_address_line_2"
                        type="text"
                        component={renderInput}
                        label="Address Line 2"
                      />
                      {'company_postal_address_line_3' && <div name={`position-company_postal_address_line_3`} />}
                      <Field
                        name="company_postal_address_line_3"
                        type="text"
                        component={renderInput}
                        label="Address Line 3"
                      />
                    </div>
                  )}
                </GridItem>
              </Grid>

              <Grid>
                <GridItem classNames={["col-md-6"]}>
                  {'company_agent' && <div name={`position-company_agent`} />}
                  <Field
                    name="company_agent"
                    type="text"
                    options={this.createAgentOptions()}
                    component={renderIndexedSelect}
                    label="Assign Agent to this Company"
                  />
                </GridItem>
                <GridItem classNames={["col-md-6"]}>
                  <Field
                    accept=".jpg, .png, .jpeg, .pdf"
                    onChange={this.handleImageUpload}
                    name="proof_of_address"
                    inputName="proof_of_address"
                    type="file"
                    component={FieldFileInput}
                    label="Upload Proof of Address (PDF only)"
                  />

                  {this.state.upload && (
                    <>
                      <br />
                      <p>Document uploaded &#10003;</p>
                    </>
                  )}
                </GridItem>
              </Grid>
              <CenteredBox noPadding>
                <Button
                  type="submit"
                  classNames={["next", "centered"]}
                  theme="green"
                  text="NEXT"
                >
                  Next
                </Button>
              </CenteredBox>
            </form>
          </FormContainer>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    agents: state.register.agents
  };
};

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(WizardFormFirstPage);

export default connect(mapStateToProps, { fetchAgents })(formWrapped);
