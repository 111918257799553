// LOADING
export const SHOW_FEEDBACK = "SHOW_FEEDBACK";
export const HIDE_FEEDBACK = "HIDE_FEEDBACK";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const ADD_ERRORS = "HIDE_FEEDBACK";
export const IS_LOADING = "IS_LOADING";

// SOCIAL FEED
export const UPDATE_SOCIAL_FEED = "UPDATE_SOCIAL_FEED";
export const FETCH_SOCIAL_FEED = "FETCH_SOCIAL_FEED";

// SERVICES
export const FETCH_SERVICES = "FETCH_SERVICES";
export const FETCH_SERVICE = "FETCH_SERVICE";

// NEWS
export const FETCH_NEWS_ARTICLES = "FETCH_NEWS_ARTICLES";
export const FETCH_NEWS_ARTICLE = "FETCH_NEWS_ARTICLE";

//PAGES
export const FETCH_PAGE = "FETCH_PAGE";

// TESTIMONIALS
export const FETCH_TESTIMONIALS = "FETCH_TESTIMONIALS";

// REGISTER
export const VERIFY_BANKING_ACCOUNT = "VERIFY_BANKING_ACCOUNT";
export const FETCH_ACCOUNT_TYPES = "FETCH_ACCOUNT_TYPES";
export const FETCH_MEMBER_REFERENCE = "FETCH_MEMBER_REFERENCE";
export const FETCH_DOCUMENTATION = "FETCH_DOCUMENTATION";
export const FETCH_INSTITUTIONS = "FETCH_INSTITUTIONS";
export const REGISTER = "REGISTER";
export const CREATE_REGISTER_PAYMENT = "CREATE_REGISTER_PAYMENT";
export const CREATE_PAYFAST_FORM = "CREATE_PAYFAST_FORM";
export const FETCH_AGENTS = "FETCH_AGENTS";
export const FETCH_MEMBER_DETAILS = "FETCH_MEMBER_DETAILS";
export const VERIFY_PAYMENT_TYPE = "VERIFY_PAYMENT_TYPE";

// LOGIN
export const LOGIN = "LOGIN";

// 4 IN 1 REPORTS
export const FETCH_4_IN_1_TOKEN = "FETCH_DOCUMENTATION";
export const FETCH_4_IN_1_QUESTIONS = "FETCH_4_IN_1_QUESTIONS";
export const SUBMIT_4_IN_1_QUESTIONS = "SUBMIT_4_IN_1_QUESTIONS";
export const CREATE_4_IN_1_PAYMENT = "CREATE_4_IN_1_PAYMENT";
export const GENERATE_REPORT = "GENERATE_REPORT";
export const REMOVE_QUESTIONS = "REMOVE_QUESTIONS";

// AGE GATE
export const VERIFY_AGE = "VERIFY_AGE";
