import React from 'react'
import { textTruncate } from '../../../../utils/functions'

export default function SocialCardContent(props) {
  return (
    <div className="preview-text">
      {textTruncate(props.article.attachments.facebook_post.message, 80, '...')}
    </div>
  )
}
