import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { generateReport } from "../../actions/index";
import Container from "../ui/container/Container";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import { getQueryString } from "../../utils/functions/index";
import HideOnMobile from "../ui/structure/hide-on-mobile/HideOnMobile";
import { downloadPDF } from "../../utils/functions/index";
import Loader from "../ui/loader/Loader";

class PaymentSuccess extends Component {
  generateReport = async () => {
    const tokens = JSON.parse(
      decodeURIComponent(getQueryString("public_4in1_tokens"))
    );
    await this.props.generateReport(tokens.public_4in1_token);
    console.log(this.props.fourInOneReport);
    if (!_.isEmpty(this.props.fourInOneReport)) {
      downloadPDF(this.props.fourInOneReport.report.public_4in1_report_data);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <div
            className="form-success"
            style={{
              height: "600px",
              position: "relative",
              display: "flex",
              justifyContent: "left",
              alignItems: "center"
            }}
          >
            <div className="thank-you-content">
              <Text type="h2" text="Payment successful!" />
              <Text type="p" text="We wish to thank you for your payment." />
              <Text
                type="p"
                text="Please click on the button below to generate your report."
              />
              <hr />
              <br />

              {this.props.isLoading && <Loader />}

              {!this.props.isLoading && (
                <Button
                  onClick={this.generateReport}
                  theme="green"
                  text="GENERATE REPORT"
                />
              )}
            </div>

            <HideOnMobile>
              <div
                className="thank-you-image"
                style={{ position: "absolute", bottom: 0, right: 0 }}
              >
                <img
                  src="/assets/image/people/Lady.png"
                  alt=""
                  style={{ maxWidth: "340px" }}
                />
              </div>
            </HideOnMobile>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    fourInOneReport: state.fourInOneReport,
    isLoading: state.isLoading
  };
};

export default connect(mapStateToProps, { generateReport })(PaymentSuccess);
