import "./ContactBar.scss";
import React from "react";
import { Container } from "reactstrap";
import IconList from "../icon/icon-list/IconList";
import IconButton from "../icon/icon-button/IconButton";

export default function ContactBar() {
  return (
    <div className="contact-bar">
      <Container>
        <div className="contact-bar-grid">
          <div className="contact-grid-item">
            <IconList>
              <a href="tel:0861 90 90 90">
                <IconButton classNames={[]}>
                  <img src="/assets/image/icons/white/phone-icon.svg" alt="" />
                  <span className="m-l-10">0861 90 90 90</span>
                </IconButton>
              </a>

              <a href="mailto:sales@accountability.co.za">
                <IconButton classNames={[]}>
                  <img src="/assets/image/icons/white/mail-icon.svg" alt="" />
                  <span className="m-l-10">sales@accountability.co.za</span>
                </IconButton>
              </a>
            </IconList>
          </div>

          <div className="contact-grid-item justify-right">
            <IconList>
              <a
                href="https://www.facebook.com/accountabilityGroup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton classNames={[]}>
                  <img
                    src="/assets/image/icons/white/facebook-icon.svg"
                    alt=""
                  />
                </IconButton>
              </a>

              <a
                href="https://www.instagram.com/accountabilitygroup/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton classNames={[]}>
                  <img
                    src="/assets/image/icons/white/instagram-icon.svg"
                    alt=""
                  />
                </IconButton>
              </a>

              <a
                href="https://www.linkedin.com/company/accountability-group-pty-ltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton classNames={[]}>
                  <img
                    src="/assets/image/icons/white/linkedin-icon.svg"
                    alt=""
                  />
                </IconButton>
              </a>

              <a
                href="https://twitter.com/AccyGroup"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton classNames={[]}>
                  <img
                    src="/assets/image/icons/white/twitter-icon.svg"
                    alt=""
                  />
                </IconButton>
              </a>
            </IconList>
          </div>
        </div>
      </Container>
    </div>
  );
}
