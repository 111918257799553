import { ADD_ERRORS } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case ADD_ERRORS:
      return action.payload
    default:
      return state
  }
}
